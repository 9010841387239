import React from "react";
import { graphql } from 'gatsby'
import ReactMarkdown from "react-markdown";
import { resetWebflow, createHubSpotForm } from "../utils/common";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Notification from "../components/header/notification";
import Layout from "../layouts";

export default ({
  data: {
    datoCmsLandingPage4: { content, hubspotFormId, seoMetaTags, notificationPage },
    allDatoCmsNotification: { edges: notifications }
  }
}) => {
  resetWebflow();
  if (hubspotFormId) {
    createHubSpotForm({
      formId: hubspotFormId,
      targetId: "#hubsportForm"
    })
  }
  return (
    <Layout>
      <HelmetDatoCms seo={seoMetaTags} />
      <Notification
        notifications={notifications}
        linkedPage={notificationPage}
      />
      <div class="white-section centered full-demo">
        <div class="container">
          <ReactMarkdown source={content} escapeHtml={false} />
          <div className="sm-spacing"></div>
          <div className="w-embed w-script subscriber-container" id="hubsportForm" />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query LandingPage4Query($slug: String!) {
    datoCmsLandingPage4(slug: { eq: $slug }) {
      content
      hubspotFormId
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      notificationPage {
        id
        page
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
